<template>
  <a-select
    :disabled="disabled || options.length === 0"
    v-if="type === 'select' || type === 'multiselect'"
    v-model:value="valueState"
    size="large"
    :style="{ width: '100%' }"
    :mode="type === 'multiselect' && 'multiple'"
  >
    <a-select-option v-for="option in options" :key="option.label" :value="option.value">{{
      option.label
    }}</a-select-option>
  </a-select>
  <a-date-picker v-else-if="type === 'date'" :disabled="disabled" show-time v-model:value="valueState" />
  <a-input v-else v-model:value="valueState" :disabled="disabled" />
</template>

<script>
import { useVModel } from '@/composable/useVModel.js';

export default {
  name: 'MultipleFormElement',
  emits: ['update:value'],
  props: {
    value: [String, Number, Array],
    options: Array,
    type: String,
    disabled: Boolean,
  },
  setup(props) {
    return {
      valueState: useVModel(props, 'value'),
    };
  },
};
</script>
