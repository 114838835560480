import { computed, getCurrentInstance } from 'vue';

export const useVModel = (props, propName) => {
  const vm = getCurrentInstance().proxy;

  return computed({
    get() {
      if (props.type === 'multiselect') {
        return props[propName] ? String(props[propName]).split(',') : [];
      } else {
        return props[propName] ? String(props[propName]) : '';
      }
    },
    set(value) {
      if (props.type === 'multiselect') {
        vm.$emit(`update:${propName}`, value.join(','));
      } else {
        vm.$emit(`update:${propName}`, value);
      }
    },
  });
};
