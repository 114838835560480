<template>
  <Container>
    <CardToolbox>
      <sdPageHeader :title="'Product details (' + completeness + '%)'" class="product-details-header">
        <template #buttons>
          <sdButton class="btn-signin" type="primary" @click="onSave" :disabled="isSaving || isLoading">Save</sdButton>
        </template>
      </sdPageHeader>
    </CardToolbox>
    <Main>
      <FileCardWrap>
        <BasicFormWrapper class="product-details-form-wrapper">
          <div v-if="isSaving || isLoading" class="spin">
            <a-spin />
          </div>
          <a-form v-else name="multi-form" layout="horizontal">
            <sdCards v-for="(element, index) in form" :key="`ele_${index}`" :title="element.label">
              <a-row v-for="(row, index) in element.children" :key="`row_${index}`" :gutter="20" class="mb-20">
                <a-col v-for="(col, index) in row" :key="`col_${index}`" :sm="12" :xs="12">
                  <a-form-item :label="col.default_frontend_label">
                    <MultipleFormElement
                      v-model:value="formModels[col.attribute_code]"
                      :options="col.options"
                      :type="col.frontend_input"
                      :disabled="col.attribute_code === 'sku'"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </sdCards>
            <sdCards v-if="form && form.length" title="Additional Fields">
              <a-row class="mb-20">
                <a-col :xs="24">
                  <a-form-item label="Description">
                    <ckeditor :editor="editor" v-model="description" :config="editorConfig" class="ckeditor"></ckeditor>
                  </a-form-item>
                </a-col>
              </a-row>
            </sdCards>
          </a-form>
        </BasicFormWrapper>
      </FileCardWrap>
      <a-row justify="end">
        <a-col>
          <a-popconfirm title="Are you sure delete this product?" @confirm="onDelete(productData.sku)">
            <sdButton type="warning" class="mb-25 btn-delete" :disabled="isSaving || isLoading || disableDelete"
              >Delete</sdButton
            >
          </a-popconfirm></a-col
        >
      </a-row>
    </Main>
  </Container>
</template>

<script>
import { FileCardWrap, Container } from './style';
import { computed, ref, defineComponent, watch, onMounted } from 'vue';
import { BasicFormWrapper, CardToolbox, Main } from '@/view/styled';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import MultipleFormElement from '@/components/multiple-form-element/MultipleFormElement.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default defineComponent({
  name: 'ProductDetails',
  components: { FileCardWrap, BasicFormWrapper, MultipleFormElement, CardToolbox, Main, Container },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        height: '300px',
      },
    };
  },
  setup() {
    const { dispatch, state } = useStore();
    const { params } = useRoute();

    const productData = computed(() => state.omni.product);
    const attributes = computed(() => state.omni.attributes);
    const childProductsLoading = computed(() => state.omni.childProductLoading);
    const isSaving = computed(() => state.omni.saving);
    const isLoading = computed(() => state.omni.loading);
    const isNewProduct = computed(() => params.sku === 'new');
    const description = computed({
      get() {
        const descriptionAttribute = productData.value?.custom_attributes?.find(
          (attribute) => attribute.attribute_code === 'description',
        );
        return descriptionAttribute ? descriptionAttribute.value : '';
      },
      set(value) {
        const descriptionAttributeIndex = productData.value?.custom_attributes?.findIndex(
          (attribute) => attribute.attribute_code === 'description',
        );

        if (descriptionAttributeIndex > -1) {
          productData.value.custom_attributes[descriptionAttributeIndex].value = value;
        } else {
          productData.value.custom_attributes = [
            ...productData.value.custom_attributes,
            {
              attribute_code: 'description',
              value,
            },
          ];
        }
      },
    });
    const completeness = computed(() => {
      const completenessFields = [
        'name',
        'sku',
        'price',
        'national_code',
        'tax_class_id',
        'brand',
        'enhanced_title',
        'description',
        'product_type',
      ];

      const existFieldsCount = completenessFields.filter((field) => {
        const isCustomField = productData.value?.custom_attributes?.findIndex(
          (attribute) => attribute.attribute_code === field,
        );

        return isCustomField > -1 || Object.keys(productData.value).includes(field);
      });

      return Math.round((existFieldsCount.length / completenessFields.length) * 100);
    });

    const onSave = async () => {
      if (params.sku === 'new') {
        await dispatch('createProduct');
      } else {
        const payload = {
          sku: formModels.value.sku,
          data: makePayload(productData.value),
        };
        await dispatch('updateProduct', payload);
      }
    };

    const disableDelete = computed(() => {
      const isEpc = state.omni?.product?.custom_attributes?.find((attr) => attr.attribute_code === 'is_epc');
      if (isEpc && isEpc.value === 'yes') return true;
      return false;
    });
    const onDelete = async () => {
      await dispatch('deleteProduct', {
        sku: productData.value.sku,
        forceRouteChange: false,
      });
    };

    const getLabel = (options) => options[0];

    const form = ref({});
    const formModels = ref({});
    const whiteListElements = computed(() => {
      let attributes = state.auth.user.attributes;
      let productInfos = attributes.filter((data) => data.group === 1).map((data) => data?.code);
      let pricing = attributes.filter((data) => data.group === 2).map((data) => data?.code);
      let shipping = attributes.filter((data) => data.group === 3).map((data) => data?.code);
      let options = attributes.filter((data) => data.group === 4).map((data) => data?.code);

      let product = state.omni.product;
      let result = [];
      if (productInfos?.length) {
        result.push({
          label: 'Product Information',
          value: 'product_information',
          children: productInfos?.length ? productInfos : [],
        });
      }
      if (pricing?.length && product.type_id !== 'configurable') {
        result.push({
          label: 'Pricing',
          value: 'pricing',
          children: pricing?.length ? pricing : [],
        });
      }
      if (shipping?.length) {
        result.push({
          label: 'Shipping',
          value: 'shipping',
          children: shipping?.length ? shipping : [],
        });
      }
      if (options?.length) {
        result.push({
          label: 'Options',
          value: 'options',
          children: options?.length ? options : [],
        });
      }
      return result;
    });
    console.log(whiteListElements, 'whiteListElements');
    // const whiteListElements = [
    //   {
    //     label: 'Product Information',
    //     value: 'product_information',
    //     children: ['sku', 'status', 'brand', 'enhanced_title', 'product_type'],
    //   },
    //   {
    //     label: 'Pricing',
    //     value: 'pricing',
    //     children: ['price', 'special_price', 'special_from_date', 'special_to_date', 'tax_class_id'],
    //   },
    //   {
    //     label: 'Shipping',
    //     value: 'shipping',
    //     children: ['weight', 'collection_only'],
    //   },
    //   {
    //     label: 'Options',
    //     value: 'options',
    //     children: ['color_simple'],
    //   },
    // ];

    watch(attributes, async () => {
      await loadForm();
    });

    // watch(
    //   form,
    //   () => {
    //     commit('setChanged', true);
    //   },
    //   { deep: true },
    // );

    onMounted(async () => {
      await loadForm();
    });

    const loadForm = async () => {
      if (attributes.value && attributes.value.length) {
        const chunk = (arr, size) =>
          Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
        const availableFormElements = whiteListElements.value.reduce((result, ele) => {
          return [...result, ...ele.children];
        }, []);
        const filteredData = attributes.value.filter(
          (d) =>
            d.default_frontend_label &&
            d.attribute_code !== 'description' &&
            availableFormElements.includes(d.attribute_code),
        );

        form.value = whiteListElements.value.map(({ label, value, children }) => {
          const childElements = filteredData.filter((d) => children.includes(d.attribute_code));
          return {
            label,
            value,
            children: chunk(childElements, 2),
          };
        });

        formModels.value = filteredData.reduce((result, d) => {
          return { ...result, [d.attribute_code]: getFormElementValue(d.attribute_code) };
        }, {});
      }
    };

    const getFormElementValue = (label) => {
      if (!label) return '';
      const attribute = productData.value.custom_attributes.find((att) => att.attribute_code === label);
      if (attribute) {
        return attribute.value;
      } else {
        if (label === 'status') return productData.value[label] === 1 ? 1 : 2;
        return productData.value[label];
      }
    };

    const makePayload = (product) => {
      let data = product;

      Object.entries(formModels.value).map(([key, value]) => {
        const attributeIndex = data.custom_attributes.findIndex((att) => att.attribute_code === key);

        if (attributeIndex > -1) {
          data.custom_attributes[attributeIndex]['value'] = value;
        } else if (key in data) {
          data[key] = value;
        } else {
          if (value) {
            data.custom_attributes = [...data.custom_attributes, { attribute_code: key, value }];
          }
        }
      });

      let { custom_attributes } = data;
      let result = custom_attributes?.filter((attribute) => attribute.attribute_code !== 'category_ids');
      if (result?.length) data.custom_attributes = result;

      let { extension_attributes } = data;
      if (extension_attributes?.category_links) {
        delete extension_attributes.category_links;
        data.extension_attributes = extension_attributes;
      }
      console.log(data, 'payload for product');
      return data;
    };
    console.log(form, 'form');
    console.log(formModels, 'formModels');
    return {
      productData,
      onSave,
      isSaving,
      isLoading,
      isNewProduct,
      childProductsLoading,
      description,
      getLabel,
      onDelete,
      form,
      getFormElementValue,
      formModels,
      disableDelete,
      completeness,
    };
  },
});
</script>

<style>
.ck-editor__main > .ck-content > ul > li {
  list-style: inside;
}
</style>
