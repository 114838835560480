<template>
  <sdCards title="Media Upload" class="mt-45">
    <template #button>
      <file-upload
        class="file-selector mr-4"
        :extensions="extensions"
        :accept="accept"
        :multiple="multiple"
        :directory="directory"
        :size="size || 0"
        :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
        :drop="drop"
        :add-index="addIndex"
        name="fileUploader"
        v-model="files"
        @input-filter="inputFilter"
        ref="upload"
      >
        <sdButton size="default" type="primary" :disabled="isSaving">Select</sdButton>
      </file-upload>
      <sdButton class="btn-signin" :outline="true" type="primary" @click="onUpload" :disabled="isSaving">Save</sdButton>
    </template>
    <FileCardWrap>
      <div v-if="isSaving || loading" class="spin">
        <a-spin />
      </div>
      <MediaUploadWrapper v-else>
        <div v-if="!files.length" class="no-files">
          <h2>
            Drop files anywhere to upload
            <br />or
          </h2>
          <label for="fileUploader">
            <!-- <sdButton size="default" type="primary">
              <sdFeatherIcons type="upload" size="14" />
              <span>Select Files</span>
            </sdButton>-->
            Select Files
          </label>
        </div>
        <div v-else>
          <draggable class="drag-area list-group w-full mb-25" :list="files" tag="a-row">
            <template #item="{ element }">
              <a-col :xxl="8" :xl="12" :lg="12" :sm="12" :xs="24">
                <FileCard :data="element" @remove="removeFile" />
              </a-col>
            </template>
          </draggable>
        </div>
      </MediaUploadWrapper>
    </FileCardWrap>
  </sdCards>
</template>

<script>
import { FileCardWrap, MediaUploadWrapper } from './style';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import draggable from 'vuedraggable';
import FileCard from '@/components/file/Card';

export default {
  name: 'MediaUpload',
  components: {
    FileCardWrap,
    MediaUploadWrapper,
    draggable,
    FileCard,
  },
  setup() {
    const { state, dispatch } = useStore();
    const productData = computed(() => state.omni.product);
    const loading = computed(() => state.omni.loading);
    const user = computed(() => state.auth.user);
    const isSaving = ref(false);
    const files = ref([]);
    const upload = ref(null);
    const savedFiles = ref([]);

    const inputFilter = (newFile, oldFile) => {
      if (newFile && newFile.error === '' && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
        if (
          newFile &&
          newFile.error === '' &&
          newFile.type.substr(0, 6) === 'image/' &&
          newFile.blob &&
          (!oldFile || newFile.blob !== oldFile.blob)
        ) {
          newFile.error = 'image parsing';
          let img = new Image();
          img.onload = () => {
            upload.value.update(newFile, {
              error: '',
              height: img.height,
              width: img.width,
            });
          };
          img.οnerrοr = () => {
            upload.value.update(newFile, {
              error: 'parsing image size',
            });
          };
          img.src = newFile.blob;
        }
      }
    };

    const onUpload = async () => {
      try {
        let base64Files = [...files.value];
        isSaving.value = true;
        let sendingFiles = [];

        savedFiles.value.map((file, index) => {
          file['position'] = index;
          const isCurrentFile = base64Files.find((f) => f.id === file.id);
          if (!isCurrentFile) {
            sendingFiles.push({
              name: file.name,
              action: 'remove',
              data: {
                ...file,
                fileBase64: null,
              },
            });
          }
        });

        await Promise.all(
          base64Files.map(async (file, index) => {
            file['position'] = index;
            if (file.file) {
              const reader = new FileReader();
              reader.readAsDataURL(file.file);

              const fileBase64 = await new Promise((resolve, reject) => {
                reader.onload = () => {
                  resolve(reader.result);
                };
                reader.onerror = () => {
                  reject('Error');
                };
              });

              sendingFiles.push({
                name: file.name,
                action: 'new',
                data: {
                  ...file,
                  fileBase64,
                },
              });
            } else {
              sendingFiles.push({
                name: file.name,
                action: 'keep',
                data: {
                  ...file,
                  fileBase64: null,
                },
              });
            }
          }),
        );

        // const imageIds = productData.value.media_gallery_entries.map(image => image.id);

        const result = await dispatch('updateProductMedia', {
          files: sendingFiles,
          // imageIds,
        });

        console.log(result, 'result');

        isSaving.value = false;
      } catch (err) {
        isSaving.value = false;
      }
    };

    const removeFile = (fileId) => {
      files.value = files.value.filter((file) => file.id !== fileId);
    };

    const render = () => {
      savedFiles.value = [];

      if (productData?.value && productData.value.media_gallery_entries) {
        isSaving.value = true;

        savedFiles.value = productData.value.media_gallery_entries.map((image) => {
          const urlAry = image.file.split('/');
          const fileName = urlAry[urlAry.length - 1].split('.')[0];
          const fileUrl = user.value.image_base_url + image.file;

          return {
            thumb: fileUrl,
            name: fileName,
            type: image.media_type,
            size: 0,
            position: image.position,
            magento_id: image.id,
          };
        });

        savedFiles.value.sort((a, b) => a.position - b.position);

        savedFiles.value.map((file) => {
          upload.value.add(file);
        });

        isSaving.value = false;
      }
    };

    watch(
      () => productData.value?.media_gallery_entries,
      (val) => {
        if (val) {
          // files.value = [];
          upload.value.clear();
          render();
        }
      },
    );
    // watch(
    //   files,
    //   async () => {
    //     commit('setChanged', true);
    //   },
    //   { deep: true },
    // );

    onMounted(() => {
      render();
    });

    return {
      productData,
      isSaving,
      files,
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      thread: 3,
      drop: true,
      addIndex: false,
      inputFilter,
      upload,
      onUpload,
      removeFile,
      loading,
      savedFiles,
    };
  },
};
</script>

<style lang="scss">
.file-selector {
  margin-right: 10px;
}
</style>
