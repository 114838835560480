<template>
  <sdCards title="National Codes" class="mt-45">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="onSave" :disabled="isSaving || isLoading">Save</sdButton>
    </template>
    <FileCardWrap>
      <div v-if="isSaving || isLoading" class="spin">
        <a-spin />
      </div>
      <a-form v-else name="multi-form" layout="horizontal" :wrapper-col="{ span: 12 }">
        <a-row :gutter="30" align="middle">
          <a-col :xs="12">
            <a-form-item label="Brand" :style="{ alignItems: 'center' }">
              <a-select v-model:value="formState.brand" @change="fetchTreeData">
                <a-select-option v-for="brand in brands" :key="brand.code" :value="brand">
                  {{ brand.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :xs="12">
            <a-form-item label="Model" :style="{ alignItems: 'center' }">
              <a-select
                v-model:value="formState.model"
                :loading="modelLoading"
                :disabled="!models.length"
                @change="fetchTreeData"
              >
                <a-select-option v-for="model in models" :key="model" :value="model">
                  {{ model }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </a-row>
        <a-row :gutter="30">
          <a-col :xs="24">
            <div v-if="treeLoading" class="tree-loading">
              <a-spin v-if="true" />
            </div>
            <a-directory-tree
              v-else
              checkable
              multiple
              :show-icon="false"
              :tree-data="treeData"
              :checkedKeys="checkedKeys"
              @check="onTreeItemCheck"
            />
          </a-col>
        </a-row>
      </a-form>
    </FileCardWrap>
  </sdCards>
</template>

<script>
import { computed, onMounted, ref, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { FileCardWrap } from './style';
import { makeTreeObjectFromList, sortTreeObject, makeTreeFromObject } from '@/utility/utility';
import { Notification } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import orderBy from 'lodash.orderby';

export default {
  name: 'NationalCodesPage',
  components: { FileCardWrap },
  setup() {
    const store = useStore();
    const isSaving = computed(() => store.state.omni.saving);
    const isLoading = computed(() => store.state.omni.loading);
    const productData = computed(() => store.state.omni.product);
    const brands = ref([]);
    const models = ref([]);
    const modelLoading = ref(false);
    const formState = reactive({
      brand: null,
      model: null,
    });

    const treeData = ref([]);
    const treeLoading = ref(false);

    const checkedKeys = ref([]);
    const onTreeItemCheck = (currentCheckedKeys) => {
      console.log(currentCheckedKeys, 'currentCheckedKyes');
      checkedKeys.value = currentCheckedKeys.filter((key) => key.includes('mlo_code'));
    };

    const pNationalCodes = computed(() => store.getters['productMloCodes']);
    let checkedNationalCodes = [];

    const onSave = async () => {
      const productInstance = cloneDeep(productData.value.custom_attributes && productData.value, true);

      if (productInstance && productInstance.custom_attributes) {
        const hasNationalCodeAttribute = productInstance.custom_attributes.find(
          (attribute) => attribute.attribute_code === 'mlo_code',
        );
        if (hasNationalCodeAttribute) {
          productInstance.custom_attributes.forEach((code) => {
            if (code.attribute_code === 'mlo_code') {
              code.value = [
                ...checkedNationalCodes,
                ...checkedKeys.value.map((key) => key.split('_')[2]).filter((v) => !isNaN(parseInt(v))),
              ].join('|');
            }
          });
        } else {
          productInstance.custom_attributes.push({
            attribute_code: 'mlo_code',
            value: [
              ...checkedNationalCodes,
              ...checkedKeys.value.map((key) => key.split('_')[2]).filter((v) => !isNaN(parseInt(v))),
            ].join('|'),
          });
        }

        await store.dispatch('updateProduct', {
          sku: productInstance.sku,
          data: productInstance,
        });
      }
    };

    const fetchModels = async () => {
      modelLoading.value = true;
      treeData.value = [];
      formState.model = null;
      const {
        data: { result },
      } = await DataService.post(`vehicle/vehicle-selector/step/model`, {
        Brand: formState.brand,
      });

      models.value = result.sort((a, b) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
      });
      modelLoading.value = false;
    };

    const fetchTreeData = async () => {
      treeLoading.value = true;
      const {
        data: { result },
      } = await DataService.post(`vehicle/vehicle-selector/national-code`, {
        Brand: formState.brand,
        // Model: formState.model,
        mode: 'multi',
      });
      const treeSteps = ['Model', 'Model_Version', 'Bodystyle', 'Year'];
      const treeObject = makeTreeObjectFromList(result, treeSteps);
      const treeItems = sortTreeObject(makeTreeFromObject(treeObject, 'Model'));
      treeData.value = orderBy(
        treeItems,
        function (item) {
          return item.title;
        },
        ['asc'],
      );
      // treeData.value = [...treeData.value];
      result.map((r) => {
        if (pNationalCodes.value.includes(String(r['mlo_code']))) {
          checkedNationalCodes = checkedNationalCodes.filter((code) => +code !== r['mlo_code']);
          checkedKeys.value = [...checkedKeys.value, `mlo_code_${r['mlo_code']}`];
        }
      });
      treeLoading.value = false;
    };

    const setUpModels = async () => {
      if (productData?.value?.sku) {
        try {
          const modelsFileName = 'brands.json';
          brands.value = await import(`@/static/json/${modelsFileName}`).then((module) => module.default);

          checkedNationalCodes = [...pNationalCodes.value];
        } catch (error) {
          Notification['error']({
            message: 'JSON file does not exist',
          });
        }
      }
    };

    watch(productData, async () => {
      await setUpModels();
    });
    // watch(
    //   formState,
    //   async () => {
    //     store.commit('setChanged', true);
    //   },
    //   { deep: true },
    // );
    onMounted(async () => {
      await setUpModels();
    });

    return {
      pNationalCodes,
      onSave,
      isSaving,
      treeData,
      checkedKeys,
      onTreeItemCheck,
      isLoading,
      brands,
      formState,
      models,
      modelLoading,
      fetchModels,
      fetchTreeData,
      treeLoading,
    };
  },
};
</script>

<style lang="scss">
.tree-loading {
  display: flex;
  justify-content: center;
  min-height: 125px;
  align-items: center;
}
</style>
