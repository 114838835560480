<template>
  <sdCards title="Tyre fitment" class="mt-45">
    <!-- <template #button>
      <sdButton class="btn-signin" type="primary" @click="onSave" :disabled="isSaving || isLoading">Save</sdButton>
    </template> -->
    <FileCardWrap>
      <div v-if="isSaving || isLoading" class="spin">
        <a-spin />
      </div>
      <a-form v-else name="multi-form" layout="horizontal" :wrapper-col="{ span: 12 }">
        <a-row :gutter="30" align="middle">
          <a-col :xs="24">
            <h2>Tire Size: {{ national_code ? national_code : 'None' }}</h2>
          </a-col>
          <!-- <a-col :xs="12">
            <a-form-item label="Model" :style="{ alignItems: 'center' }">
              <a-select
                v-model:value="formState.model"
                :loading="modelLoading"
                :disabled="!models.length"
                @change="fetchTreeData"
              >
                <a-select-option v-for="model in models" :key="model" :value="model">
                  {{ model }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </a-row>
        <h3>Change the tire size</h3>
        <a-row :gutter="30">
          <a-col :md="6" :xs="12">
            <!-- <a-form-item :style="{ alignItems: 'center', width: '100%' }"> -->
            <a-select
              v-model:value="tyre.width"
              :loading="modelLoading"
              :disabled="!widths.length"
              @change="fetchRims"
              placeholder="Select Width"
              :style="{ alignItems: 'center', width: '100%' }"
            >
              <a-select-option v-for="model in widths" :key="model" :value="model">
                {{ model }}
              </a-select-option>
            </a-select>
            <!-- </a-form-item> -->
          </a-col>
          <a-col :md="6" :xs="12">
            <!-- <a-form-item placeholder="Select Rim" :style="{ alignItems: 'center' }"> -->
            <a-select
              v-model:value="tyre.rim"
              :loading="modelLoading"
              :disabled="!rims.length"
              @change="fetchProfiles"
              placeholder="Select Rim"
              :style="{ alignItems: 'center', width: '100%' }"
            >
              <a-select-option v-for="model in rims" :key="model" :value="model">
                {{ model }}
              </a-select-option>
            </a-select>
            <!-- </a-form-item> -->
          </a-col>
          <a-col :md="6" :xs="12">
            <!-- <a-form-item placeholder="Select Profile" :style="{ alignItems: 'center' }"> -->
            <a-select
              v-model:value="tyre.profile"
              :loading="modelLoading"
              :disabled="!profiles.length"
              @change="fetchNationalCodes"
              placeholder="Select Profile"
              :style="{ alignItems: 'center', width: '100%' }"
            >
              <a-select-option v-for="model in profiles" :key="model" :value="model">
                {{ model }}
              </a-select-option>
            </a-select>
            <!-- </a-form-item> -->
          </a-col>
          <a-col :md="6" :xs="12">
            <a-button
              type="primary"
              @click="
                setNationalCodeToProduct();
                $message.success('National Code is set!');
              "
            >
              Set Size
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </FileCardWrap>
  </sdCards>
</template>

<script>
import { computed, onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex';
// import cloneDeep from 'lodash.clonedeep';
import { FileCardWrap } from './style';
// import { makeTreeObjectFromList, sortTreeObject, makeTreeFromObject } from '@/utility/utility';
// import { Notification } from 'ant-design-vue';
// import { DataService } from '@/config/dataService/dataService';

export default {
  name: 'TyreFitmentPage',
  components: { FileCardWrap },
  setup() {
    const { state, dispatch, getters, commit } = useStore();
    const isSaving = computed(() => state.omni.saving);
    const isLoading = computed(() => state.omni.loading);
    const tyre = computed(() => state.omni.tyre);
    const widths = computed(() => state.omni.widths);
    const rims = computed(() => state.omni.rims);
    const profiles = computed(() => state.omni.profiles);
    const national_code = computed(() => state.omni.national_code);

    const modelLoading = ref(false);
    const formState = reactive({
      brand: null,
      model: null,
    });

    const treeData = ref([]);
    const treeLoading = ref(false);

    const checkedKeys = ref([]);
    const onTreeItemCheck = (currentCheckedKeys) => {
      checkedKeys.value = currentCheckedKeys.filter((key) => key.includes('nationalCode'));
    };

    const pNationalCodes = computed(() => getters['productNationalCodes']);

    const fetchRims = async () => {
      await dispatch('fetchRims', { width: tyre.value.width });
    };

    const fetchProfiles = async () => {
      await dispatch('fetchProfiles', { rim: tyre.value.rim, width: tyre.value.width });
    };

    const fetchNationalCodes = async () => {
      await dispatch('fetchNationalCodes', tyre.value);
    };

    const setNationalCodeToProduct = async () => {
      commit('setNationalCodeToProduct');
      await dispatch('updateProduct');
    };

    onMounted(async () => {
      await dispatch('fetchWidths');
    });

    return {
      pNationalCodes,
      isSaving,
      treeData,
      checkedKeys,
      onTreeItemCheck,
      isLoading,
      formState,
      modelLoading,
      treeLoading,
      tyre,
      widths,
      rims,
      profiles,
      fetchRims,
      fetchProfiles,
      fetchNationalCodes,
      setNationalCodeToProduct,
      national_code,
    };
  },
};
</script>

<style lang="scss">
.tree-loading {
  display: flex;
  justify-content: center;
  min-height: 125px;
  align-items: center;
}
</style>
