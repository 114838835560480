<template>
  <sdCards title="Product Children" class="mt-45">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="onCreate" :disabled="isSaving">Create New Child</sdButton>
    </template>
    <div v-if="isLoading || childProductsLoading || isSaving" class="spin">
      <a-spin />
    </div>
    <TableWrapper v-else class="mb-25">
      <a-table :dataSource="childTableData" :columns="childTableColumns" />
    </TableWrapper>
  </sdCards>

  <a-modal
    v-model:visible="visibleChildProductModal"
    :title="isCreate ? `Create Child Product` : `Update Child Product`"
    @ok="onChildProductUpdate"
    width="60%"
  >
    <BasicFormWrapper>
      <ChildProductModalWrapper>
        <a-row v-if="modalLoading" justify="center" align="middle" :style="{ minHeight: '200px' }">
          <a-col>
            <a-spin />
          </a-col>
        </a-row>
        <a-form v-if="!modalLoading && !isCreate" name="multi-form" layout="horizontal">
          <sdCards v-for="(element, index) in modalFormElements" :key="`ele_${index}`" :title="element.label">
            <a-row v-for="(row, index) in element.children" :key="`row_${index}`" :gutter="20" class="mb-20">
              <a-col v-for="(col, index) in row" :key="`col_${index}`" :sm="6" :xs="12">
                <a-form-item :label="col.default_frontend_label">
                  <MultipleFormElement
                    v-model:value="modalFormModels[col.attribute_code]"
                    :options="col.options"
                    :type="col.frontend_input"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </sdCards>
          <!-- <sdCards v-if="modalFormElements && modalFormElements.length" title="Special Fields">
              <a-row class="mb-20">
                <a-col :xs="24">
                  <a-form-item label="Description">
                    <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                  </a-form-item>
                </a-col>
              </a-row>
            </sdCards> -->
        </a-form>
        <a-form v-if="!modalLoading && isCreate">
          <a-row :gutter="20" class="mb-20">
            <a-col :md="12" :xs="24">
              <a-form-item label="sku">
                <a-input v-model:value="newChildFormModel.sku" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24">
              <a-form-item label="Price">
                <a-input type="number" step="0.001" v-model:value="newChildFormModel.price" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row
            v-for="(row, index) in newChildFormElements[0].children"
            :key="`row_${index}`"
            :gutter="20"
            class="mb-20"
          >
            <a-col v-for="(col, index) in row" :key="`col_${index}`" :sm="6" :xs="12">
              <a-form-item :label="col.default_frontend_label">
                <MultipleFormElement
                  v-model:value="newChildFormModel[col.attribute_code]"
                  :options="col.options"
                  :type="col.frontend_input"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </ChildProductModalWrapper>
    </BasicFormWrapper>
  </a-modal>
</template>

<script>
import { TableWrapper, BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';
import MultipleFormElement from '@/components/multiple-form-element/MultipleFormElement.vue';
import { ChildProductModalWrapper } from './style';
import { Notification } from 'ant-design-vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const childTableColumns = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Attributes',
    dataIndex: 'attributes',
    key: 'attributes',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

export default {
  name: 'ChildProducts',
  components: { TableWrapper, BasicFormWrapper, MultipleFormElement, ChildProductModalWrapper },
  setup() {
    const { dispatch, state, commit } = useStore();
    const { push } = useRouter();
    const { params } = useRoute();

    const productData = computed(() => state.omni.product);
    const attributes = computed(() => state.omni.attributes);
    const isSaving = ref(false);
    const isLoading = computed(() => state.omni.loading);
    const user = computed(() => state.auth.user);
    const activeStoreView = computed(() => state.auth.activeStoreView);
    /*** ----  Utilities block ----  ***/
    const chunk = (arr, size) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
    const getFormElementValue = (product, label) => {
      if (!label) return '';
      const attribute = product.custom_attributes.find((attr) => attr.attribute_code === label);

      return attribute ? attribute.value : product[label];
    };
    const getAllAttributes = async (product) => {
      const {
        data: { data, status: resStatus },
      } = await DataService.get(
        `${activeStoreView.value.code}/products/attribute-sets/${product.attribute_set_id}/attributes`,
      );

      if (resStatus === 'success') {
        const allChildAttributes = childOptionWhiteList.reduce((result, ele) => {
          return [...result, ...ele.children];
        }, []);
        return data.filter(
          (d) =>
            d.default_frontend_label &&
            d.attribute_code !== 'description' &&
            allChildAttributes.includes(d.attribute_code),
        );
      }

      return [];
    };
    /*** ---- End utilities block ----  ***/

    const childProductsLoading = computed(() => state.omni.childProductLoading);
    const childProductsOptions = ref({});
    const childProducts = computed(() => state.omni.childProducts);

    const modalLoading = ref(false);
    const visibleChildProductModal = ref(false);
    const modalFormModels = ref({});
    const modalFormElements = ref({});
    const currentChildPSku = ref({});
    const currentChildProduct = computed(() => {
      const p = childProducts.value.find((product) => product.sku === currentChildPSku.value);
      return p ? p : {};
    });
    const attrsFromParent = ref([]);
    let childOptionWhiteList = [];

    const onChildProductUpdate = async () => {
      const makePayload = (product) => {
        let data = product;
        if (!isCreate.value) {
          Object.entries(modalFormModels.value).map(([key, value]) => {
            const attributeIndex = data.custom_attributes.findIndex((att) => att.attribute_code === key);

            if (attributeIndex > -1) {
              data.custom_attributes[attributeIndex]['value'] = value;
            } else if (key in data) {
              data[key] = value;
            } else {
              if (value) {
                data.custom_attributes = [...data.custom_attributes, { attribute_code: key, value }];
              }
            }
          });
        } else {
          const additionalAttributes = childOptionWhiteList.find((option) => option.value === 'additional');
          data.custom_attributes = [];
          data.attribute_set_id = productData.value.attribute_set_id;
          Object.entries(newChildFormModel.value).map(([key, value]) => {
            const isAdditionalAttr = additionalAttributes.children.includes(key);
            if (value) {
              if (isAdditionalAttr) {
                data.custom_attributes = [...data.custom_attributes, { attribute_code: key, value }];
              } else {
                data[key] = value;
              }
            }
          });
        }

        return data;
      };

      const product = isCreate.value ? makePayload({}) : makePayload(currentChildProduct.value);
      const payload = {
        sku: product.sku,
        data: product,
      };
      visibleChildProductModal.value = false;
      isSaving.value = true;
      if (isCreate.value) {
        let response = await DataService.post(`${activeStoreView.value.code}/products`, { product });
        if (response.data.status === 'success') {
          response = await DataService.post(
            `${activeStoreView.value.code}/configurable-products/${productData.value.sku}/child`,
            {
              childSku: product.sku,
            },
          );

          if (response.data.status === 'success' && response.data.data === true) {
            await dispatch('fetchProduct', {
              sku: productData.value.sku,
              disableLoading: false,
            });
            Notification['success']({
              message: 'Assigned child products successfully.',
            });
          }
        }
      } else {
        await dispatch('updateProduct', payload);
      }
      isSaving.value = false;
    };

    const childTableData = computed(() => {
      return childProducts.value.map((product, index) => {
        const { id, price, sku, status, custom_attributes } = product;
        const labelColors = ['cyan', 'geekblue'];
        const attributes = attrsFromParent.value.map((option) => {
          const productAttr = custom_attributes.find((attribute) => attribute.attribute_code === option.code);
          if (productAttr) {
            const attrOption = option.options.find((o) => +productAttr.value === +o.value);
            return attrOption ? `${option.label}: ${attrOption.label}` : '';
          } else {
            return '';
          }
        });
        const onDelete = async () => {
          isSaving.value = true;
          await dispatch('deleteProduct', {
            sku,
            forceRouteChange: true,
          });
          isSaving.value = false;
        };
        const onEdit = async () => {
          isCreate.value = false;
          modalLoading.value = true;
          visibleChildProductModal.value = true;
          const availableAttributes = await getAllAttributes(product);
          modalFormElements.value = childOptionWhiteList.map(({ label, value, children }) => {
            const childElements = availableAttributes.filter((d) => children.includes(d.attribute_code));
            return {
              label,
              value,
              children: chunk(childElements, 4),
            };
          });
          modalFormModels.value = availableAttributes.reduce((result, d) => {
            return { ...result, [d.attribute_code]: getFormElementValue(product, d.attribute_code) };
          }, {});
          currentChildPSku.value = sku;
          modalLoading.value = false;
        };
        return {
          key: id,
          sku,
          price,
          status: (
            <div class="taglist-wrap">
              <a-tag color={labelColors[+status - 1]}>{+status == 1 ? 'Enabled' : 'Disabled'}</a-tag>
            </div>
          ),
          attributes: attributes.join(', '),
          action: (
            <div class="table-actions">
              <sdButton class="btn-icon" type="default" shape="circle" onClick={() => onEdit(index)}>
                <sdFeatherIcons type="edit" size="16" />
              </sdButton>
              <a-popconfirm title="Sure to delete?" onConfirm={() => onDelete()}>
                <sdButton class="btn-icon" type="default" shape="circle">
                  <sdFeatherIcons type="trash" size="16" />
                </sdButton>
              </a-popconfirm>
            </div>
          ),
        };
      });
    });

    const getLabel = (options) => options[0];

    const loadData = async () => {
      if (productData.value?.extension_attributes?.configurable_product_options) {
        attrsFromParent.value = [];
        childOptionWhiteList = [
          {
            label: 'Main Attributes',
            value: 'main',
            children: ['sku', 'status', 'special_price', 'enhanced_title', 'news_from_date'],
          },
        ];
        commit('childProductLoadingBegin');
        await Promise.all(
          productData.value.extension_attributes.configurable_product_options.map(async (option) => {
            const {
              data: { data, status },
            } = await DataService.get(
              `${activeStoreView.value.code}/products/attributes/${option.attribute_id}/options`,
            );

            if (status === 'success') {
              attributes.value.map((attribute) => {
                if (+attribute.attribute_id === +option.attribute_id) {
                  attrsFromParent.value = [
                    ...attrsFromParent.value,
                    {
                      label: attribute.default_frontend_label,
                      options: data,
                      id: option.id,
                      code: attribute.attribute_code,
                    },
                  ];
                }
              });
              childProductsOptions.value[option.label.toLowerCase()] = data;
            }
          }),
        );
        childOptionWhiteList = [
          ...childOptionWhiteList,
          {
            label: 'Additional Attributes',
            value: 'additional',
            children: attrsFromParent.value.map((attr) => attr.code),
          },
          {
            label: 'Options',
            value: 'options',
            children: ['color_simple'],
          },
        ];
        commit('childProductLoadingEnd');
      } else {
        console.log(productData.value, childProducts.value);
        if (
          Object.keys(productData.value).length !== 0 &&
          (productData.value.type_id !== 'configurable' || childProducts?.value.length === 0)
        ) {
          push({
            name: 'omni-product-details',
            params: { sku: params.sku },
          });
        }
      }
    };

    watch(attributes, async () => {
      await loadData();
    });
    onMounted(async () => {
      await loadData();
    });

    /*** ----  createForm block ----  ***/
    const isCreate = ref(false);
    const newChildFormElements = ref({});
    const newChildFormModel = ref({
      sku: null,
      price: null,
      visibility: 1,
      status: 1,
      type_id: 'simple',
      attribute_set_id: 4,
      name: productData.value.name,
      weight: '0.5',
    });
    const onCreate = async () => {
      isCreate.value = true;
      modalLoading.value = true;
      visibleChildProductModal.value = true;
      console.log(productData.value, 'productData');
      const availableAttributes = await getAllAttributes(productData.value);
      const additionalAttributes = childOptionWhiteList.filter((option) => option.value === 'additional');
      newChildFormElements.value = additionalAttributes.map(({ label, value, children }) => {
        const childElements = availableAttributes.filter((d) => children.includes(d.attribute_code));
        return {
          label,
          value,
          children: chunk(childElements, 4),
        };
      });
      newChildFormModel.value.sku = null;
      newChildFormModel.value.price = null;
      newChildFormModel.value.name = productData.value.name;
      newChildFormModel.value = availableAttributes.reduce((result, d) => {
        return { ...result, [d.attribute_code]: '' };
      }, newChildFormModel.value);
      modalLoading.value = false;
    };
    /*** ----  End createForm block ----  ***/

    return {
      productData,
      isSaving,
      isLoading,
      childTableColumns,
      childTableData,
      childProductsLoading,
      visibleChildProductModal,
      onChildProductUpdate,
      childProductsOptions,
      getLabel,
      currentChildProduct,
      modalLoading,
      childOptionWhiteList,
      modalFormModels,
      modalFormElements,
      attrsFromParent,
      onCreate,
      isCreate,
      newChildFormElements,
      newChildFormModel,
      user,
    };
  },
};
</script>
